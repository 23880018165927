.main{
    /* background-color: rgb(255, 255, 255); */
    /* display: flex; */
    /* flex-direction: column; */
    color:rgb(250, 245, 245);
    font-size: 20px;
    font-weight: bolder;
    /* justify-content: center; */
    /* z-index: 5; */
}
#select{
    color:white;
    background-color: black;
    border: solid 1px rgb(172, 14, 235);
    padding: 7px;
    font-size: 25px;
    width: 30%;
    height: 50px;
    margin: auto;
    margin-top: 1%;
    font-family: Georgia, 'Times New Roman', Times, serif;
}
option{
    background-color: rgb(52, 54, 47);
    margin-top: 1px;
}
#submit{
    height: 50px;
    background-color: brown;
    border: 1px solid blue ;
    margin-left: 10px;
    color: white;
    padding: 10px;
    font-size: 18px;
    width: 10%;
}
#city{
    position: absolute;
    /* width: 30%; */
    left: 5%;
    font-size: 80px;
    color: rgb(31, 11, 8);
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    /* background-color: pink; */
    /* margin-left:; */
}
#date{
    position: absolute;
    /* width: 30%; */
    top: 22%;
    color: rgb(29, 4, 29);
    font-size: 30px;
    right: 9%; 
    /* background-color: red; */
}
#temp{
    position: absolute; 
    font-size: 150px;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    /* background-color:wheat; */
    /* width: 32%; */
    left: 37%;
    color: rgb(68, 47, 8);
    top:18%; 
}
#environment{
    position: absolute;
    /* background-color: yellow; */
    /* width: 50%; */
    color:rgb(30, 18, 49);
    top: 70%;
    left: 40%;
    font-family: serif;
    font-size: 60px;
}
#speed{
    position: absolute;
    bottom: 15%;
    left: 2%;
    color: rgb(53, 16, 53);
    /* width: 60%; */
    font-size: 40px;
    /* background-color: turquoise; */
}
#humidity{
    position: absolute;
    /* background-color: violet; */
    font-size: 40px;
    bottom: 18%;
    right: 3%;
    color: rgb(63, 24, 17);
    /* width: auto; */
}
#english{
    border: 1px solid blue ;
    margin-left: 10px;
    color: white;
    padding: 10px;
    font-size: 18px;
    width: 10%;
    background-color: gold;
}
#hindi{
    border: 1px solid blue ;
    margin-left: 10px;
    color: white;
    padding: 10px;
    font-size: 18px;
    width: 10%;
    /* height: 25px; */
    background-color: gold;
}   
#background{
    border: 0px;
    font-size: 17px;
    padding: 4px;
    color: white;
    position: absolute;
    bottom: 1%;
    right: 25%;
    width: 50%;
    height: 25px;
    background-color: rgb(71, 7, 114);
}
@media screen and (max-width:400px){
    .main{
        display: flex;
        flex-direction: column;
        color:rgb(250, 245, 245);
        font-size: 20px;
        font-weight: bolder;
        justify-content: center;
        justify-items: center;
        /* z-index: 5; */
    }
    #select{
        position: absolute;
        color:white;
        background-color: black;
        /* border: solid 1px rgb(172, 14, 235); */
        padding: 7px;
        font-size: 22px;
        width: 80%;
        margin: auto;
        top: 14%;
        left: 10%;
        font-family: Georgia, 'Times New Roman', Times, serif;
    }
    option{
        /* background-color: rgb(52, 54, 47); */
        margin-top: 1px;;
    }
    #submit{
        position: absolute;
        background-color: black;
        height: 35px;
        width: 70%;
        top: 24%;
        left: 15%;
        margin: auto;
        color: white;
        padding: 3px;
        font-size: 20px;
        border-radius: 15px;
        border: 1px solid rgb(167, 106, 248);
        /* box-shadow: 2px 2px 5px black; */
    }
    #city{
        /* position: absolute; */
        width: 100%;
        left: 0;
        top: 32%;
        color: wheat;
        /* justify-self: center; */
        /* margin: auto; */
        font-size: 50px;
        font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
        /* background-color: pink; */
    }
    
    #date{
        position: absolute;
        width: 100%;
        top: 45%;
        color: white;
        font-size: 20px;
        right: 0%; 
        /* background-color: red; */
    }
    #temp{
        position: absolute; 
        font-size: 80px;
        font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
        /* background-color:wheat; */
        width: 100%;
        left: 0%;
        color: rgb(147, 161, 238);
        top:50%;
    }
    #environment{
        position: absolute;
        /* background-color: yellow; */
        width: 100%;
        color:wheat;
        top: 71%;
        left: 0%;
        font-family: serif;
        font-size: 30px;
    }
    #speed{
        position: absolute;
        bottom: 8%;
        left: 0%;
        width: 100%;
        color: white;
        font-size: 20px;
        /* background-color: turquoise; */
    }
    #humidity{
        position: absolute;
        /* background-color: violet; */
        font-size: 20px;
        bottom: 15%;
        color: white;
        right: 0%;
        width: 100%;
    }
    #english{
        border: 0px;
        font-size: 17px;
        padding: 4px;
        color: rgb(6, 17, 165);
        position: absolute;
        bottom: 1%;
        left: 1%;
        width: 20%;
        height: 25px;
        background-color: gold;
    }
    #hindi{
        border: 0px;
        font-size: 17px;
        padding: 4px;
        color: rgb(6, 17, 165);
        position: absolute;
        bottom: 1%;
        right: 1%;
        width: 20%;
        height: 25px;
        background-color: gold;
    }   
    #background{
        border: 0px;
        font-size: 17px;
        padding: 4px;
        color: white;
        position: absolute;
        bottom: 1%;
        right: 25%;
        width: 50%;
        height: 25px;
        background-color: rgb(71, 7, 114);
    }
}
