.App {
  text-align: center;
}
body{
  background-image: url('./components/image/weather.jpg');
  background-size: cover;
  /* opacity: 0.5; */
  /* z-index: 1; */
}
@media screen and (max-width:400px){
  body{
    /* background-color: wheat; */
    background-image: url('./components/image/1.jpg');
    background-size: cover;
    /* background-image: none; */
  }

}